import React from 'react';

import './terms.css';

export interface TermsProps {
  style?: React.CSSProperties;
}

export const Terms: React.FC<TermsProps> = ({ style }) => {
  return (
    <div className="Terms" style={style}>
      <p className="Text">
        © 2024 Fins Car Wash
        <br /><br />
        <a
          className="Link"
          href="https://whistleexpresscarwash.com/terms-and-conditions/"
        >
          Terms and Conditions 
        </a>&nbsp;/&nbsp; 
        <a className="Link" href="https://whistleexpresscarwash.com/privacy-policy/">Privacy Policy</a><br />
        <a
          className="Link"
          href="https://whistleexpresscarwash.com/disclaimer/"
        >
          Disclaimer
        </a>&nbsp;/&nbsp;
        <a className="Link" href="https://whistleexpresscarwash.com/accessibility-statement/">Accessibility Statement</a><br />
      </p>
    </div>
  );
};

export default Terms;
