import React from 'react';
import { IconProps } from './types';

const FinsWashIcon: React.FC<IconProps> = ({
  color = '#ffffff',
  secondaryColor = '#94cc00',
  height = 49.61,
  width = 260,
  style = {}
}) => {
  return (
    <svg
      className="Fins-icon Icon"
      aria-hidden="true"
      focusable="false"
      role="img"
      style={{ width, height, ...style }}
      viewBox="0 0 352 67.163"
    >
      <g transform="translate(-13.879 -8.495)">
        <g transform="translate(13.879 28.906)">
          <g>
            <path
              d="M34.844,43.2a1.208,1.208,0,0,1,1.367,1.367v5.1a1.227,1.227,0,0,1-1.367,1.368H23.358A1.227,1.227,0,0,0,21.991,52.4v1.458a1.247,1.247,0,0,0,1.367,1.368h7.52A1.208,1.208,0,0,1,32.246,56.6v5.1a1.227,1.227,0,0,1-1.367,1.368h-7.52a1.227,1.227,0,0,0-1.367,1.367v9.3A1.227,1.227,0,0,1,20.624,75.1H15.247a1.248,1.248,0,0,1-1.368-1.368V44.565A1.227,1.227,0,0,1,15.247,43.2Z"
              transform="translate(-13.879 -42.469)"
              fill={color}
            />
            <path
              d="M61.411,43.2a1.209,1.209,0,0,1,1.368,1.367V73.733A1.228,1.228,0,0,1,61.411,75.1H56.4a1.248,1.248,0,0,1-1.368-1.368V44.565A1.227,1.227,0,0,1,56.4,43.2Z"
              transform="translate(-29.962 -42.469)"
              fill={color}
            />
            <path
              d="M83.3,43.2a1.572,1.572,0,0,1,1.595,1.048l6.2,15.176c.182.411.547.32.547-.136V44.565A1.227,1.227,0,0,1,93,43.2h5.1a1.208,1.208,0,0,1,1.367,1.367V73.733A1.227,1.227,0,0,1,98.11,75.1h-6.7a1.574,1.574,0,0,1-1.6-1.048l-6.2-15.176c-.183-.41-.547-.319-.547.136V73.733A1.228,1.228,0,0,1,81.7,75.1H76.6a1.248,1.248,0,0,1-1.367-1.368V44.565A1.227,1.227,0,0,1,76.6,43.2Z"
              transform="translate(-37.857 -42.469)"
              fill={color}
            />
            <path
              d="M137.514,54.033c-.911.182-1.367-.228-1.5-1.139a3.7,3.7,0,0,0-3.965-3.054c-1.777,0-2.871,1.048-2.689,2.1.638,3.555,15.04,3.464,15.04,13.946,0,1.367-1.55,9.434-12.351,9.434-9.571,0-11.621-6.29-11.849-9.844a1.338,1.338,0,0,1,1.185-1.5l5.15-1.139c.911-.182,1.413.228,1.5,1.185a3.737,3.737,0,0,0,4.01,3.463c2.233,0,3.874-1.321,3.6-2.552-.866-3.874-14.584-3.008-14.584-13.49-.046-1.367.775-9.434,10.983-9.434,9.389,0,11.576,6.107,11.85,9.388a1.339,1.339,0,0,1-1.185,1.5Z"
              transform="translate(-55.429 -42.001)"
              fill={color}
            />
          </g>
        </g>
        <g transform="translate(172.401 28.815)">
          <g transform="translate(0)">
            <g>
              <path
                d="M274.1,52.926c0-3.008.638-11.075,11.439-11.075,9.707,0,11.211,6.517,11.393,10.026a1.2,1.2,0,0,1-1,1.276l-5.059,1.14a1.527,1.527,0,0,1-1.823-1.413c-.228-2.005-1.048-3.19-3.509-3.19-3.373,0-3.6,2.142-3.6,5.651V61.9c0,3.509.228,5.651,3.6,5.651,2.506,0,3.19-1.185,3.463-3.282a1.421,1.421,0,0,1,1.778-1.321l5.15,1.14a1.212,1.212,0,0,1,1,1.321c-.183,3.464-1.687,9.981-11.393,9.981-10.8,0-11.439-8.067-11.439-11.074Z"
                transform="translate(-274.102 -41.851)"
                fill={color}
              />
            </g>
            <g transform="translate(25.752)">
              <path
                d="M338.707,73.024c0,1-.456,1.55-1.368,1.55h-5.1a1.227,1.227,0,0,1-1.367-1.368V69.242a1.209,1.209,0,0,0-1.367-1.368h-3.92a1.228,1.228,0,0,0-1.367,1.368v3.965a1.209,1.209,0,0,1-1.367,1.368h-5.1c-.866,0-1.367-.547-1.367-1.55V53.7c0-5.743.911-11.85,11.166-11.85s11.166,6.107,11.166,11.85ZM330.868,53.7c0-2.188-.319-3.282-3.327-3.282s-3.327,1.094-3.327,3.282v4.967a1.247,1.247,0,0,0,1.367,1.368h3.92a1.26,1.26,0,0,0,1.367-1.413Z"
                transform="translate(-316.375 -41.851)"
                fill={color}
              />
            </g>
            <g transform="translate(51.275 0.821)">
              <path
                d="M382.292,72.64a1.615,1.615,0,0,1-1.5,2.461h-5.2a1.111,1.111,0,0,1-1.048-.638L369.8,65.3a.464.464,0,0,0-.364-.228h-1.824a1.364,1.364,0,0,0-1.412,1.413v7.2a1.365,1.365,0,0,1-1.413,1.413h-5.1a1.365,1.365,0,0,1-1.413-1.413V44.611a1.365,1.365,0,0,1,1.413-1.413h9.981c8.112,0,11.393,3.464,11.393,10.938,0,4.239-1.048,7.2-3.464,8.933a.481.481,0,0,0-.136.592Zm-14.676-21.6A1.364,1.364,0,0,0,366.2,52.45v3.372a1.364,1.364,0,0,0,1.412,1.413h2.325c2.188,0,3.281-.274,3.281-3.1s-1.093-3.1-3.281-3.1Z"
                transform="translate(-358.273 -43.198)"
                fill={color}
              />
            </g>
          </g>
          <g transform="translate(80.041)">
            <path
              d="M405.534,44.7a1.125,1.125,0,0,1,1.23-1.5h5.423a1.351,1.351,0,0,1,1.5,1.185l2.6,19c.091.456.456.456.547,0l3.053-19a1.378,1.378,0,0,1,1.5-1.185h3.919a1.377,1.377,0,0,1,1.5,1.185l3.054,19c.091.456.456.456.546,0l2.6-19a1.35,1.35,0,0,1,1.5-1.185h5.424a1.125,1.125,0,0,1,1.23,1.5l-5.606,29.213a1.377,1.377,0,0,1-1.5,1.185h-6.426a1.405,1.405,0,0,1-1.5-1.185L423.626,62.7c-.091-.456-.456-.456-.547,0l-2.506,11.211a1.406,1.406,0,0,1-1.5,1.185h-6.426a1.377,1.377,0,0,1-1.5-1.185Z"
              transform="translate(-405.494 -42.377)"
              fill={color}
            />
            <path
              d="M490.065,73.024c0,1-.456,1.55-1.368,1.55h-5.1a1.228,1.228,0,0,1-1.368-1.368V69.242a1.209,1.209,0,0,0-1.367-1.368H476.94a1.228,1.228,0,0,0-1.368,1.368v3.965a1.209,1.209,0,0,1-1.368,1.368h-5.1c-.866,0-1.368-.547-1.368-1.55V53.7c0-5.743.912-11.85,11.166-11.85s11.166,6.107,11.166,11.85ZM482.226,53.7c0-2.188-.319-3.282-3.327-3.282s-3.327,1.094-3.327,3.282v4.967a1.248,1.248,0,0,0,1.368,1.368h3.919a1.261,1.261,0,0,0,1.367-1.413Z"
              transform="translate(-429.818 -41.851)"
              fill={color}
            />
            <path
              d="M526.5,54.033c-.911.182-1.367-.228-1.5-1.139a3.7,3.7,0,0,0-3.965-3.054c-1.777,0-2.871,1.048-2.689,2.1.639,3.555,15.039,3.464,15.039,13.946,0,1.367-1.55,9.434-12.35,9.434-9.571,0-11.621-6.29-11.849-9.844a1.337,1.337,0,0,1,1.184-1.5l5.15-1.139c.911-.182,1.413.228,1.5,1.185a3.738,3.738,0,0,0,4.011,3.463c2.233,0,3.874-1.321,3.6-2.552-.866-3.874-14.584-3.008-14.584-13.49C510,50.068,510.823,42,521.032,42c9.389,0,11.576,6.107,11.849,9.388a1.338,1.338,0,0,1-1.184,1.5Z"
              transform="translate(-446.017 -41.91)"
              fill={color}
            />
            <path
              d="M560.772,43.2a1.247,1.247,0,0,1,1.367,1.367V55.959a1.247,1.247,0,0,0,1.368,1.367h4.375a1.227,1.227,0,0,0,1.367-1.367V44.565a1.247,1.247,0,0,1,1.368-1.367h5.1a1.208,1.208,0,0,1,1.367,1.367V73.733a1.227,1.227,0,0,1-1.367,1.368h-5.1a1.248,1.248,0,0,1-1.368-1.368v-7.2a1.208,1.208,0,0,0-1.367-1.367h-4.375a1.227,1.227,0,0,0-1.368,1.367v7.2a1.227,1.227,0,0,1-1.367,1.368h-5.1a1.248,1.248,0,0,1-1.368-1.368V44.565a1.209,1.209,0,0,1,1.368-1.367Z"
              transform="translate(-463.651 -42.377)"
              fill={color}
            />
          </g>
        </g>
        <g transform="translate(110.386 8.494)">
          <path
            d="M226.3,73.888l-1.085-1.851c-.093-.173-.2-.358-.323-.553l0,0c-.875-1.4-2.333-3.235-2.531-3.522-.127-.185-1.478-2.058-1.584-2.2-5.785-7.628-12.04-12.074-21.421-15.629-.786-.3-1.51.633-1.228,1.585a78.865,78.865,0,0,1,2.28,9.171,89.1,89.1,0,0,1,.534,11.13l-.124,2.148A.906.906,0,0,0,201.6,75.2H225.75C226.3,75.2,226.618,74.438,226.3,73.888Zm-3.546-.462h-2.83a.287.287,0,0,1-.259-.208,39.581,39.581,0,0,0-11.735-16.34c-.074-.06-.01-.2.069-.152A43.208,43.208,0,0,1,222.983,72.88C223.111,73.112,222.978,73.426,222.75,73.426Z"
            transform="translate(-182.368 -24.746)"
            fill={color}
          />
          <g>
            <path
              d="M214.814,29.822a4.661,4.661,0,1,0-7.78-3.6A27.3,27.3,0,0,0,176.26,66.663a4.866,4.866,0,0,0,6.328,7.176,27.3,27.3,0,1,0,32.226-44.016ZM199.6,75.895a23.286,23.286,0,0,1-14.733-5.237,4.871,4.871,0,0,0-4.768-5.876c-.132,0-.261.01-.39.02A23.394,23.394,0,1,1,199.6,75.895Z"
              transform="translate(-172.3 -13.655)"
              fill={secondaryColor}
            />
            <circle
              cx="2.957"
              cy="2.957"
              r="2.957"
              transform="translate(31.364)"
              fill={secondaryColor}
            />
            <path
              d="M251.7,109.787a2.729,2.729,0,1,0,2.73,2.729A2.729,2.729,0,0,0,251.7,109.787Z"
              transform="translate(-202.264 -48.082)"
              fill={secondaryColor}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FinsWashIcon;
