import React from 'react';
import classnames from 'classnames';
import { NavItem } from '../../utils/types';
import SocialMedia from '../social-media/';
import ContactUsAndHours from '../contact-us-and-hours';
import Terms from '../terms';
import Subscribe from '../subscribe';
import FinsWashIcon from '../icons/finswash';
import './footer.css';

export interface FooterProps {
  items?: Array<NavItem>;
  selected?: number;
  onNavItemSelected?: (index: number) => void;
  newsletter?: boolean;
}

const FooterLink: React.FC<{
  index: number;
  selected: boolean;
  onSelected?: (index: number) => void;
}> = ({ index, selected, onSelected, children }) => {
  function handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    onSelected ? onSelected(index) : null;
  }
  return (
    <a
      className={classnames({
        'Footer-link': true,
        'Footer-link__is-selected': selected
      })}
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

const socialFooterAndTerms = (
  <div className="Footer__social">
    <SocialMedia />
    <Terms />
  </div>
);

const Footer: React.FC<FooterProps> = ({
  items,
  selected,
  onNavItemSelected,
  newsletter = true
}) => {
  return (
    <footer id="Footer">
      {newsletter ? <Subscribe /> : null}
      <div className="Footer__container">
        <div
          className="Footer__mobile"
          style={{ marginBottom: 'var(--spacing-lg)' }}
        >
          <FinsWashIcon width="217" height="41.4" />
        </div>
        <div className="Footer__desktop">
          <FinsWashIcon width="443" height="84.53" />
          {socialFooterAndTerms}
        </div>
        <div className="Footer__contact-info">
          <div className="Footer-links">
            {items?.map((item, idx) => (
              <FooterLink
                key={item.label}
                index={idx}
                selected={selected === idx}
                onSelected={onNavItemSelected}
              >
                {item.label}
              </FooterLink>
            ))}
          </div>
          <ContactUsAndHours />
        </div>
        <div
          className="Footer__mobile"
          style={{ marginTop: 'var(--spacing-lg)' }}
        >
          {socialFooterAndTerms}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
