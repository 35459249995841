import React from 'react';
import { IconProps } from './types';

const FacebookIcon: React.FC<IconProps> = ({
  color = 'rgb(59, 89, 153)',
  secondaryColor = 'var(--pallet-white)',
  height = 32,
  width = 32,
  style
}) => {
  return (
    <svg
      className="Icon"
      aria-hidden="true"
      focusable="false"
      role="img"
      height={height}
      width={width}
      style={style}
      viewBox="0 0 32 32"
    >
      <circle fill={color} r="16" cy="16" cx="16"></circle>
      <path
        transform="scale(0.045) translate(175,100)"
        fill={secondaryColor}
        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
      ></path>
    </svg>
  );
};

export default FacebookIcon;
