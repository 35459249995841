import React from 'react';

import './subscribe.css';

export interface SubscribeProps {
  style?: React.CSSProperties;
}

export const Subscribe: React.FC<SubscribeProps> = ({ style }) => {
  return (
    <div className="Subscribe" id="Subscribe">
      <h5 className="Subscribe-header">
        Subscribe to Our Newsletter for Updates & Exclusive Offers!
      </h5>
      <form
        className="Subscribe-form"
        action="https://cl.s10.exct.net/subscribe.aspx?lid=1595"
        name="Subscribe-form"
        method="post"
      >
        <div className="Subscribe-input-wrapper">
          <input
            type="hidden"
            name="thx"
            value="https://finsautowash.com/newsletter-thanks/"
          />
          <input
            type="hidden"
            name="err"
            value="https://finsautowash.com/newsletter-error/"
          />
          <input
            type="hidden"
            name="usub"
            value="https://finsautowash.com/newsletter-unsubscribed/"
          />
          <input type="hidden" name="MID" value="100001951" />
          <input
            className="Subscribe-email"
            type="email"
            name="subEmail"
            id="subEmail"
            placeholder="Your Email Address"
          />
          <button type="submit" className="Subscribe-button">
            GO!
          </button>
        </div>
      </form>
    </div>
  );
};

export default Subscribe;
