import React from 'react';

import TwitterIcon from '../icons/twitter';
import FacebookIcon from '../icons/facebook';
import InstagramIcon from '../icons/instagram';
import LinkedInIcon from '../icons/linkedin';

import './social-media.css';

export interface SocialMediaProps {
  style?: React.CSSProperties;
}

export const SocialMedia: React.FC<SocialMediaProps> = ({ style }) => {
  return (
    <div className="Social" style={style}>
      <div className="Icon-container">
        <a href="https://facebook.com/finswash">
          <FacebookIcon />
        </a>
      </div>
      <div className="Icon-container">
        <a href="https://twitter.com/finswash">
          <TwitterIcon />
        </a>
      </div>
      <div className="Icon-container">
        <a href="https://instagram.com/finscarwash">
          <InstagramIcon />
        </a>
      </div>
      <div className="Icon-container">
        <a href="https://www.linkedin.com/company/finscarwash/">
          <LinkedInIcon />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
